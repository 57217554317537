<template>
  <base-dialog
    :show="!!error"
    :errorIcon="!!error"
    title="Oops. Something went wrong"
    @close="handleError"
  >
    <p>{{ error }}</p>
  </base-dialog>
  <main-card-header :title="cardTitle"></main-card-header>
  <div class="flex flex-wrap justify-center items-center" v-if="!isLoading">
    <div class="flex flex-wrap justify-center items-center w-10/12 pb-14">
      <cleaner-room-card
        v-for="room in getRooms.data"
        :key="room.id"
        :roomData="room"
      >
      </cleaner-room-card>
    </div>
  </div>
  <div v-else>
    <heart-rate fast="true"></heart-rate>
  </div>
  <my-pagination
    v-if="!!getRooms.total"
    :options="options"
    v-model="page"
    :records="getRooms.total"
    :per-page="getRooms.per_page"
    @paginate="myCallback"
  />
  <blank-paginate v-else />
</template>

<script>
import { markRaw } from "vue";
import customPaginate from "@/components/ui/baseComponents/paginate.vue";
import blankPaginate from "@/components/ui/baseComponents/blankPaginate.vue";
import cleanerRoomCard from "@/components/layout/CS/cleanerRoom.vue";
export default {
  components: { cleanerRoomCard, blankPaginate },
  created() {
    this.loadRooms();
  },
  data() {
    return {
      error: null,
      page: 1,
      options: {
        template: markRaw(customPaginate),
      },
      isLoading: false,
      cardTitle: "Rooms",
    };
  },
  methods: {
    myCallback(e) {
      this.loadRooms(e);
    },
    handleError() {
      this.error = null;
    },
    async loadRooms(page) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("cleaner/loadRooms", page);
        this.isLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
  },
  computed: {
    getRooms() {
      return this.$store.getters["cleaner/getRooms"];
    },
  },
};
</script>
