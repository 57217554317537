<template>
  <router-link :to="{ name: 'cs-room', params: { roomId: roomData.id } }">
    <div
      class=" rounded-2xl w-64 m-4 p-4 py-6 bg-white border-2 duration-500  cursor-pointer hover:shadow-2xl"
      :class="{
        'border-red': roomData.status == 0,
        'border-lightGreen': roomData.status == 1,
      }"
    >
      <div class="flex flex-col items-center justify-center ">
        <span
          class="material-icons text-7xl "
          :class="{
            'text-red': roomData.status == 0,
            'text-lightGreen': roomData.status == 1,
          }"
        >
          {{ roomData.icon }}
        </span>
        <p
          class=" text-xl font-medium mb-4 mt-4"
          :class="{
            'text-red': roomData.status == 0,
            'text-lightGreen': roomData.status == 1,
          }"
        >
          {{ roomData.name }}
        </p>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    roomData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
